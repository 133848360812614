import React, { useContext, useEffect, useState } from "react";
import { get, getparams, post, trash } from "./axios";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import Loader from "./components/loader";
import { FaRegTrashAlt } from "react-icons/fa";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import AdminHeader from "./components/adminHeader";
import { Context } from "./context";
import { Link } from "react-router-dom";

const config = {
  apiKey: "AIzaSyAF0vldKiqcTqKQFiiBqDdGZIn4PdCbeiw",
  authDomain: "kargo-a96b0.firebaseapp.com",
  projectId: "kargo-a96b0",
  storageBucket: "kargo-a96b0.appspot.com",
  messagingSenderId: "1050530103809",
  appId: "1:1050530103809:web:82229e986377dc51d7f600",
};

const app = initializeApp(config);
const defaultFirestore = getFirestore(app);
const fRequestCollection = collection(defaultFirestore, "fRequest");

const UbList = () => {
  const { state } = useContext(Context);
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const [miss, setMiss] = useState([]);

  useEffect(() => {
    setLoading(true);
    get("/cargo/ub")
      .then((res) => {
        if (res.success) {
          setList(res?.data);
        } else {
          toast.warn(res?.error);
        }
      })
      .catch((err) => {
        toast.warn(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
    get("/cargo/miss")
      .then((res) => {
        if (res.success) {
          setMiss(res?.data);
        } else {
          toast.warn(res?.error);
        }
      })
      .catch((err) => {
        toast.warn(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refresh]);

  const Ub = async (list) => {
    await post("/cargo/ub", { data: list });
  };

  const UbDelete = () => {
    setLoading(true);
    trash("/cargo/ub/0")
      .then((res) => {
        setRefresh(refresh + 1);
      })
      .catch((err) => {
        toast.warn(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const UbCheck = async () => {
    try {
      setLoading(true);
      const alist = [];

      const fetchByKey = async (key, value) => {
        if (!key || !value) return [];
        const db = query(
          fRequestCollection,
          where("status", "==", "1"),
          where(key, "==", value)
        );
        const querySnapshot = await getDocs(db);
        return querySnapshot.docs.map((doc) => doc.data());
      };

      const processMissList = async (list) => {
        return Promise.all(
          list
            .filter((el) => el?.track)
            .map(async (el) => {
              const find = await getparams("/cargo/activelist", {
                track: el.track,
              });
              if (find?.data?.length > 0 && find.data[0].arrived) {
                await trash(`/cargo/miss/${el.id}`);
              }
            })
        );
      };

      const processCargoList = async (list) => {
        return Promise.all(
          list
            .filter((el) => el?.track)
            .map(async (el) => {
              const find = await getparams("/cargo/activelist", {
                track: el.track,
              });
              if (find?.data?.length > 0 && find.data[0].arrived) {
                await trash(`/cargo/ub/${el.id}`);
              }
            })
        );
      };

      const validatePhones = (phones) => [
        ...new Set(
          phones.filter((phone) => phone && typeof phone === "string")
        ),
      ];

      if (state?.role === "admin") {
        await processMissList(miss);
      }

      await processCargoList(list);

      const invoiceRes = await get("/cargo/invoice");
      const uniquePhoneNumbers = validatePhones(
        invoiceRes?.data?.map((entry) => entry?.phone)
      );

      const ubCheckRes = await get("/cargo/ubcheck");
      const ubCheckPhones = validatePhones(
        ubCheckRes?.data?.map((entry) => entry)
      );

      const fetchCriteria = [
        ...miss.map((el) => (el?.phone ? ["phone", el.phone] : null)),
        ...miss.map((el) => (el?.track ? ["code", el.track] : null)),
        ...ubCheckPhones.map((phone) => ["phone", phone]),
        ...uniquePhoneNumbers.map((phone) => ["phone", phone]),
      ].filter(Boolean);

      const allRequests = await Promise.all(
        fetchCriteria.map(([key, value]) => fetchByKey(key, value))
      );

      alist.push(...allRequests.flat());

      const deduplicatedList = [
        ...new Map(alist.map((item) => [item?.id, item])).values(),
      ];

      if (deduplicatedList.length > 0) {
        const additionalRequests = await Promise.all(
          deduplicatedList
            .filter((el) => el.phone)
            .map((el) => fetchByKey("phone", el.phone))
        );
        alist.push(...additionalRequests.flat());

        const finalList = [
          ...new Map(alist.map((item) => [item?.id, item])).values(),
        ];

        if (finalList.length > 0) {
          await Ub(finalList);
        }
      }
    } catch (error) {
      console.error("Error in UbCheck:", error);
      toast.warn("An error occurred while processing. Please try again.");
    } finally {
      setLoading(false);
      setRefresh((prev) => prev + 1);
    }
  };

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  return (
    <div className="relative pt-0">
      {loading && <Loader />}
      <AdminHeader />
      <ConfirmDialog />
      <div className="container mx-auto">
        <div className="text-white">
          АНХААР Улаанбаатарт ачаа буугаад бүртгэл орж дууссаны дараа Цэвэрлэх
          товч дээр дараад Шалгах товч дарна уу. Тэгээд ойролцоогоор 2минут
          хүлээнэ.
        </div>
        <div className="flex gap-2 pb-2">
          <div
            className="px-4 py-2 bg-white rounded cursor-pointer"
            onClick={() => {
              UbCheck();
            }}
          >
            Шалгах
          </div>
          <div
            className="px-4 py-2 bg-red-600 text-white rounded cursor-pointer"
            onClick={() => {
              UbDelete();
            }}
          >
            Цэвэрлэх
          </div>
        </div>
        <DataTable
          value={list}
          size="small"
          className="text-sm sm:text-base"
          emptyMessage="Хоосон байна."
          header={<div>УБ-д бүртгэлтэй Эрдэнэтэд авах ачаануудын жагсаалт</div>}
        >
          <Column
            field="id"
            header="№"
            body={(data, row) => {
              return row?.rowIndex + 1;
            }}
          />
          <Column field="phone" header="Утас" />
          <Column field="track" header="Тээврийн код" />
          <Column field="create_date" header="Бүртгэсэн огноо" />
          <Column field="price" header="Үнэ" />
          <Column
            field="id"
            header="#"
            body={(data) => {
              return (
                <FaRegTrashAlt
                  className="cursor-pointer text-2xl text-red-600"
                  onClick={() => {
                    confirmDialog({
                      headerClassName: "p-2 text-center",
                      contentClassName: "p-0",
                      footerClassName: "p-2",
                      header: "Ачааны мэдээлэл устгахдаа итгэлтэй байна уу?",
                      icon: "pi pi-exclamation-triangle",
                      acceptLabel: "Тийм",
                      rejectLabel: "Үгүй",
                      accept: () => {
                        setLoading(true);
                        trash(`/cargo/ub/${data.id}`)
                          .then((res) => {
                            setRefresh(refresh + 1);
                          })
                          .catch((err) => {
                            toast.warn(err?.message);
                          })
                          .finally(() => {
                            setLoading(false);
                          });
                      },
                      reject: () => {},
                    });
                  }}
                />
              );
            }}
          />
        </DataTable>
        <DataTable
          value={miss}
          size="small"
          className="py-4 text-sm sm:text-base"
          emptyMessage="Хоосон байна."
          header={
            <div>Захиалагчын ирэхгүй байгаа ачаагаа шалгуулах хүсэлтүүд</div>
          }
        >
          <Column
            field="id"
            header="№"
            body={(data, row) => {
              return row?.rowIndex + 1;
            }}
          />
          <Column
            field="create_date"
            header="Бүртэгсэн огноо"
            className="whitespace-nowrap"
          />
          <Column
            field="status_date"
            header="Эрээн очсон өдөр"
            className="whitespace-nowrap"
          />
          <Column field="phone" header="Утас" />
          <Column field="track" header="Тээврийн код" />
          <Column field="name" header="Тайлбар" />
          {state?.role === "admin" && (
            <Column
              field="id"
              header="#"
              body={(data) => {
                return (
                  <FaRegTrashAlt
                    className="cursor-pointer text-2xl text-red-600"
                    onClick={() => {
                      setLoading(true);
                      trash(`/cargo/miss/${data.id}`)
                        .then((res) => {
                          setRefresh(refresh + 1);
                        })
                        .catch((err) => {
                          toast.warn(err?.message);
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                  />
                );
              }}
            />
          )}
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(UbList);
