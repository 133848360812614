import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "./context";
import { toast } from "react-toastify";
import { useZxing } from "react-zxing";
import { put, get, post } from "./axios";
import moment from "moment";
import _ from "lodash";
import Loader from "./components/loader";
import { ClearIcon, PasteIcon } from "./components/Icons";
import AdminHeader from "./components/adminHeader";
import { Link } from "react-router-dom";

const Camera = () => {
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const [track, setTrack] = useState("");
  const { state } = useContext(Context);
  const [phone, setPhone] = useState("");
  const [payment, setPayment] = useState("");
  const [arrived, setArrived] = useState("");
  const [is_ready, setIs_ready] = useState(true);
  const [phones, setPhones] = useState([]);
  const [track_items, setTrack_items] = useState([]);
  const [phone_items, setPhone_items] = useState([]);
  const [active, setActive] = useState(false);
  const [pshow, setPshow] = useState(false);
  const [tshow, setTshow] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveTrack = async (track) => {
    setLoading(true);
    try {
      const res = await post("/cargo/create", {
        track: track,
        registered: moment().format("YYYY-MM-DD HH:mm"),
        status: "Эрээнд ирсэн",
      });
      if (res?.success) {
        toast.success(res?.data);
      } else {
        toast.warn(res.error);
      }
    } catch (error) {
      toast.warn(error.message);
    } finally {
      setLoading(false);
    }
  };

  const check_track = async (code) => {
    if (code) {
      try {
        setLoading(true);
        const res = await get(`/cargo/check/${code?.trim()}`);
        if (res?.success) {
          if (res?.data?.length > 0) {
            if (res?.data[0]?.payment) {
              setPayment(res?.data[0]?.payment);
            }
            if (res?.data[0]?.phone) {
              setPhone(res?.data[0]?.phone);
            }
            if (res?.data[0]?.now) {
              setActive(res?.data[0]?.now === "1" ? true : false);
            }
            if (res?.data[0]?.arrived) {
              setArrived(res?.data[0]?.arrived);
            }
          } else {
            await saveTrack(code?.trim());
          }
        } else {
          toast.warn(res.error);
        }
      } catch (error) {
        toast.warn(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const { ref } = useZxing({
    onDecodeResult(result) {
      if (is_ready && !track) {
        var tcode = result?.getText();
        check_track(tcode?.trim());
        setTrack(tcode?.trim());
        setIs_ready(false);
        inputRef2.current.focus();
      }
    },
  });

  const save = async () => {
    if (track && payment) {
      try {
        setLoading(true);
        await check_track(track);
        const res = await put("/cargo/write", {
          track: track?.toUpperCase(),
          phone: phone,
          payment: payment,
          status: "Эрдэнэтэд ирсэн",
          arrived: arrived ? arrived : moment().format("YYYY-MM-DD HH:mm"),
          now: active ? 1 : 0,
        });
        if (res?.success) {
          toast.success("Амжилттай шинэчлэгдлээ.");
          setIs_ready(true);
          setTrack("");
          setPayment("");
          setArrived("");
          setPhone("");
          inputRef1.current.focus();
        } else {
          toast.warn(res.error);
        }
      } catch (error) {
        toast.warn(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      toast.warn("Track code эвсэл үнэ оруулаагүй байна.");
    }
  };

  useEffect(() => {
    setLoading(true);
    get("/cargo/ubcheck")
      .then((res) => {
        if (res?.success) {
          if (res?.data?.length > 0) {
            var pp = [];
            _.map(res?.data, (el) => {
              if (el) {
                pp.push(el);
              }
            });
            setPhones(pp);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    var pps = phones;
    if (phone) {
      pps = _.filter(pps, (item) => {
        return item?.endsWith(phone);
      });
      setPhone_items(pps);
    }
    if (phone.length > 3) {
      setPshow(true);
    }
    if (phone.length === 8) {
      setPshow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  const search_track = async (tr) => {
    if (tr) {
      const isSix = /^\d{6}$/.test(tr);
      if (isSix) {
        setLoading(true);
        const res = await get(`/cargo/check/${tr}`);
        if (res?.success) {
          const tracks = _.map(res?.data, (a) => a.track);
          setTrack_items(tracks);
          setTshow(true);
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (track) {
      search_track(track);
    }
  }, [track]);

  window.onclick = function () {
    setTshow(false);
    setPshow(false);
  };

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  if (state?.userId !== 1)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Хандах эрхгүй.</div>
        <Link
          to={"/"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нүүр хуудасруу буцах
        </Link>
      </div>
    );

  return (
    <div className="relative w-full h-full">
      <div className="fixed top-0 left-0 w-full z-[60]">
        <AdminHeader />
      </div>
      {loading && <Loader />}
      <video ref={ref} className="w-full absolute top-0 left-0" />
      <div className="w-full fixed bg-slate-300 rounded-t bottom-0 left-0 pb-2 border-b">
        <div className="flex flex-col gap-2 mt-2 px-2 w-full md:w-[700px] mx-auto">
          <div className="relative w-full">
            <input
              ref={inputRef1}
              type="text"
              className="bg-white border border-white text-gray-900 rounded block p-1.5 w-full outline-none"
              value={track}
              placeholder="Тээврийн код"
              onChange={(e) => {
                setTrack(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  check_track(e.target.value?.trim());
                  inputRef2.current.focus();
                }
              }}
            />
            {track ? (
              <div
                className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer`}
                onClick={() => {
                  setTrack("");
                  inputRef1.current.focus();
                }}
              >
                <ClearIcon />
              </div>
            ) : (
              <div
                className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer bg-white`}
                onClick={() => {
                  navigator.clipboard
                    .readText()
                    .then((text) => {
                      setTrack(text?.trim());
                      check_track(text?.trim());
                    })
                    .catch(() => {
                      toast.info("Хуулсан текст байхгүй байна.");
                    });
                }}
              >
                <PasteIcon />
              </div>
            )}
            <div
              className={`${
                tshow ? "pshow" : "hidden"
              } absolute top-10 left-2 z-10 bg-white shadow`}
            >
              {track_items?.map((el, i) => {
                return (
                  <div
                    key={i}
                    className="p-2 border-b cursor-pointer"
                    onClick={() => {
                      setTrack(el);
                      setTshow(false);
                      inputRef2.current.focus();
                    }}
                  >
                    {el}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="relative w-full">
            <input
              ref={inputRef2}
              type="number"
              inputMode="decimal"
              pattern="[0-9]"
              className="bg-white border border-white text-gray-900 rounded block p-1.5 w-full outline-none"
              value={phone}
              placeholder="Утасны дугаар"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setPshow(false);
                  inputRef3.current.focus();
                }
              }}
            />
            <div
              className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer`}
              onClick={() => {
                setPhone("");
                inputRef2.current.focus();
              }}
            >
              <ClearIcon />
            </div>
            <div
              className={`${
                pshow ? "pshow" : "hidden"
              } absolute top-10 left-2 z-10 bg-white shadow`}
            >
              {phone_items?.map((el, i) => {
                return (
                  <div
                    key={i}
                    className="p-2 border-b cursor-pointer"
                    onClick={() => {
                      setPhone(el);
                      setPshow(false);
                      inputRef3.current.focus();
                    }}
                  >
                    {el}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="relative w-full">
            <input
              ref={inputRef3}
              type="number"
              inputMode="decimal"
              pattern="[0-9]"
              className="bg-white border border-white text-gray-900 rounded block p-1.5 w-full outline-none"
              value={payment}
              placeholder="Үнэ"
              onChange={(e) => {
                setPayment(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  save();
                }
              }}
              onKeyPress={(e) => {
                if (e.nativeEvent.key === ".") {
                  e.preventDefault();
                  save();
                }
              }}
            />

            <div
              className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer`}
              onClick={() => {
                setPayment("");
                inputRef3.current.focus();
              }}
            >
              <ClearIcon />
            </div>
          </div>

          <div className="flex items-center justify-center gap-2">
            <input
              type="checkbox"
              className="w-5 h-5"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
            <div
              className="text-center px-2 py-1.5 rounded bg-green-600 text-white w-full"
              onClick={() => {
                save();
              }}
            >
              Хадгалах
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Camera);
