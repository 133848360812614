import React, { useContext, useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import { get } from "../axios";
import { Context } from "../context";
import Loader from "./loader";
import { ClearIcon } from "./Icons";
import { LuPackageCheck } from "react-icons/lu";

// const ranks = [
//   { name: "Basic", limit: "" },
//   { name: "Silver", limit: "500000" },
//   { name: "Gold", limit: "1000000" },
//   { name: "Platinum", limit: "3000000" },
//   { name: "Diamond", limit: "5000000" },
//   { name: "Titanium", limit: "10000000" },
// ];

// Цол бүрд тохирсон background болон text өнгө
const rankColors = {
  Basic: { bg: "bg-gray-200", text: "text-gray-800" },
  Silver: { bg: "bg-gray-300", text: "text-gray-600" },
  Gold: { bg: "bg-yellow-400", text: "text-yellow-800" },
  Platinum: { bg: "bg-blue-400", text: "text-blue-800" },
  Diamond: { bg: "bg-purple-500", text: "text-purple-100" },
  Titanium: { bg: "bg-gray-500", text: "text-gray-100" },
};

const CheckModal = ({ modal, setModal, value, data, Call, user }) => {
  const { state } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const userRank = user?.rank;
  const rankColor = rankColors[userRank] || {
    bg: "bg-gray-200",
    text: "text-gray-700",
  };

  const [delivery, setDelivery] = useState({
    show: false,
    phone: "",
    address: "",
  });

  const totalPayment = (Array.isArray(data) ? data : [])?.reduce(
    (accumulator, el) => {
      let payment = 0;
      if (!el?.paid) {
        payment = parseInt(el?.payment) || 0;
      }
      return accumulator + payment;
    },
    0
  );

  const is_paid = _.every(data, (el) => el?.paid);

  // const PaidAndReceived = (type) => {
  //   if (data?.length > 0) {
  //     setLoading(true);
  //     post("/cargo/PaidAndReceived", { data: data, type: type })
  //       .then((res) => {
  //         if (res.success) {
  //           toast.success("Төлбөр төлөгдөж ачааг хүлээн авлаа.");
  //           setModal(false);
  //         } else {
  //           toast.warn(res?.error);
  //         }
  //       })
  //       .catch((err) => {
  //         toast.warn(err?.message);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   } else {
  //     toast.warn("Жагсаалт хоосон байна.");
  //   }
  // };

  const PaymentCheck = async () => {
    setLoading(true);
    await get("/cargo/payment_check");
    await Call(value);
    setLoading(false);
  };

  const isPhone = /^\d{8}$/.test(value);

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } w-full h-full left-0 top-0 z-10 bg-black/50`}
        onClick={() => {
          setModal(false);
        }}
      />
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } z-20 top-[55%] md:top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2 w-[95%] md:w-[700px] max-h-[80%] h-auto overflow-auto bg-white rounded animate-fade`}
      >
        <div className="flex items-center justify-between p-2 sticky top-0 bg-white border-b z-50">
          <h1 className="font-semibold tracking-wide uppercase md:text-xl">
            {value} Ачааны мэдээлэл
          </h1>
          <div
            onClick={() => {
              setModal(false);
            }}
            className="cursor-pointer p-1"
          >
            <ClearIcon />
          </div>
        </div>
        {/* <div className="flex items-center justify-center">
          <div
            className="text-white rounded cursor-pointer text-center border px-2 py-0.5 duration-300 hover:bg-green-800"
            onClick={() => {}}
          >
            Хүргэлтээр авах
          </div>
        </div> */}
        {user && (
          <div className="flex items-center justify-center p-2">
            <div
              className={`w-80 p-5 ${rankColor.bg} rounded-lg shadow-md text-center`}
            >
              <h2 className={`font-semibold mb-2 ${rankColor.text}`}>
                {user?.phone} дугаартай хэрэглэгчийн цол
              </h2>

              <div className="relative h-6 w-full bg-gray-400 rounded-full overflow-hidden">
                <div
                  className="absolute h-full bg-green-500 transition-all duration-500"
                  style={{ width: user?.rank_percent + "%" }}
                ></div>
                <span className="absolute inset-0 flex items-center justify-center text-white font-medium">
                  {user?.rank_percent}%
                </span>
              </div>

              <div className={`mt-2 ${rankColor.text} text-sm`}>
                <p>
                  Одоогийн цол:{" "}
                  <span className="font-semibold">{user?.rank}</span>
                </p>
                <p>
                  Дараагийн цол:{" "}
                  <span className="font-semibold">{user?.next_rank}</span>
                </p>
                <p>
                  Дараагийн цолонд хүрэхэд:{" "}
                  <span className="font-semibold">{user?.rank_percent}%</span>
                </p>
              </div>
            </div>
          </div>
        )}
        <div
          className={`w-full overflow-auto text-xs md:text-base md:px-4 md:pb-4 ${
            delivery?.show ? "" : "hidden"
          }`}
        >
          <input
            type="number"
            className="bg-white border text-sm text-gray-900 rounded-l block p-2.5 w-full outline-none"
            placeholder="Утасны дугаар"
            value={delivery?.phone}
            onChange={(e) => {
              setDelivery((old) => ({ ...old, phone: e.target.value }));
              localStorage.setItem("phone", e.target.value);
            }}
          />
          <textarea
            rows={3}
            className="bg-white border text-sm text-gray-900 rounded-l block p-2.5 w-full outline-none"
            placeholder="Гэрийн хаяг тодорхой оруулна уу."
            value={delivery?.address}
            onChange={(e) => {
              setDelivery((old) => ({ ...old, address: e.target.value }));
            }}
          />
          <div
            className="px-4 py-1 text-sm bg-green-600 text-white rounded mb-4 cursor-pointer text-center"
            onClick={() => {}}
          >
            Хүргүүлэх
          </div>
        </div>
        <div
          className={`w-full overflow-auto text-xs md:text-base md:px-4 md:pb-4 ${
            delivery?.show ? "hidden" : ""
          }`}
        >
          {data?.length > 0 && is_paid && (
            <div className="text-green-600 font-semibold text-xl p-2 text-center">
              Төлбөр төлөгдсөн
            </div>
          )}
          {data?.length > 0 && totalPayment > 0 && !is_paid && isPhone && (
            <React.Fragment>
              <table className="table-auto w-full">
                <tbody>
                  <tr className="font-semibold">
                    <td className="text-left">Банк:</td>
                    <td className="text-center">Хаанбанк</td>
                    <td className="text-center">#</td>
                  </tr>
                  <tr>
                    <td className="text-left">Дансны дугаар:</td>
                    <td className="text-center font-semibold">
                      56 75 45 84 90
                    </td>
                    <td style={{ textAlign: "-webkit-center" }}>
                      <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          copy("5675458490");
                          toast.success("Дансны дугаар хуулагдлаа");
                        }}
                      >
                        <path
                          d="M20.9983 10C20.9862 7.82497 20.8897 6.64706 20.1213 5.87868C19.2426 5 17.8284 5 15 5H12C9.17157 5 7.75736 5 6.87868 5.87868C6 6.75736 6 8.17157 6 11V16C6 18.8284 6 20.2426 6.87868 21.1213C7.75736 22 9.17157 22 12 22H15C17.8284 22 19.2426 22 20.1213 21.1213C21 20.2426 21 18.8284 21 16V15"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M3 10V16C3 17.6569 4.34315 19 6 19M18 5C18 3.34315 16.6569 2 15 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3.51839 3.82475 3.22937 4.69989 3.10149 6"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">Дансны нэр:</td>
                    <td className="text-center font-semibold">Ууганбаяр</td>
                    <td className="text-center"></td>
                  </tr>
                  <tr>
                    <td className="text-left">Гүйлгээний утга:</td>
                    <td className="text-center font-semibold">{value}</td>
                    <td style={{ textAlign: "-webkit-center" }}>
                      <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          copy(value);
                          toast.success("Гүйлгээний утга хуулагдлаа");
                        }}
                      >
                        <path
                          d="M20.9983 10C20.9862 7.82497 20.8897 6.64706 20.1213 5.87868C19.2426 5 17.8284 5 15 5H12C9.17157 5 7.75736 5 6.87868 5.87868C6 6.75736 6 8.17157 6 11V16C6 18.8284 6 20.2426 6.87868 21.1213C7.75736 22 9.17157 22 12 22H15C17.8284 22 19.2426 22 20.1213 21.1213C21 20.2426 21 18.8284 21 16V15"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M3 10V16C3 17.6569 4.34315 19 6 19M18 5C18 3.34315 16.6569 2 15 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3.51839 3.82475 3.22937 4.69989 3.10149 6"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">Нийт дүн:</td>
                    <td className="text-center font-semibold">
                      {totalPayment}
                    </td>
                    <td style={{ textAlign: "-webkit-center" }}>
                      <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          copy(totalPayment);
                          toast.success("Мөнгөн дүн хуулагдлаа");
                        }}
                      >
                        <path
                          d="M20.9983 10C20.9862 7.82497 20.8897 6.64706 20.1213 5.87868C19.2426 5 17.8284 5 15 5H12C9.17157 5 7.75736 5 6.87868 5.87868C6 6.75736 6 8.17157 6 11V16C6 18.8284 6 20.2426 6.87868 21.1213C7.75736 22 9.17157 22 12 22H15C17.8284 22 19.2426 22 20.1213 21.1213C21 20.2426 21 18.8284 21 16V15"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M3 10V16C3 17.6569 4.34315 19 6 19M18 5C18 3.34315 16.6569 2 15 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3.51839 3.82475 3.22937 4.69989 3.10149 6"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="text-red-500 font-medium px-4 text-center">
                Гүйлгээний утга дээр утасны дугаар заавал бичнэ үү.
              </div>
            </React.Fragment>
          )}
          {/* <div
            className={`${
              data?.length > 0 && totalPayment > 0 ? "flex" : "hidden"
            } items-center justify-center flex-col my-2 text-xs md:text-base`}
          >
            {state?.loggedIn && (
              <div className="flex items-center justify-center gap-6">
                <div
                  className="px-4 py-1 bg-green-600 text-white rounded cursor-pointer"
                  onClick={() => PaidAndReceived("cash")}
                >
                  Бэлэн
                </div>
                <div
                  className="px-4 py-1 bg-green-600 text-white rounded cursor-pointer"
                  onClick={() => PaidAndReceived("account")}
                >
                  Данс
                </div>
              </div>
            )}

            <div className="flex items-center justify-center gap-4">
            <a
              href="khanbank://q?qPay_QRcode=0002010102121531279404962794049600240211593822827540014A00000084300010108AGMOMNUB0220000000000056754584905204829953034965802MN591010019904666011ULAANBAATAR625801151000000006208300510shiljuuleg0721-SH4QanmvP_iUxPx3gMEv7106QPP_QR78151000000006208307902148002026304B109"
              style={{ textAlign: "-webkit-center" }}
            >
              <img
                src="./1.png"
                alt="Khan Bank"
                className="w-[74px] h-[74px] object-cover md:w-[120px] md:h-[120px] rounded-3xl border"
              />
              <span>Khan Bank</span>
            </a>
          </div>
          </div> */}

          {data?.length > 0 && totalPayment > 0 && !is_paid && isPhone && (
            <div className="px-4 py-1 text-white text-sm flex items-center justify-between sm:text-base bg-green-600">
              <div className="flex items-center">
                <LuPackageCheck size={20} className="mx-1" /> Таньд{" "}
                {data?.length} бараа ирсэн байна.
              </div>
              {!is_paid && (
                <div
                  className="text-white rounded cursor-pointer text-center border px-2 py-0.5 duration-300 hover:bg-green-800"
                  onClick={() => PaymentCheck()}
                >
                  Төлбөр шалгах
                </div>
              )}
            </div>
          )}
          {data !== "Бүртгэл хийж байна." ? (
            <table className="table-auto w-full">
              {data?.length > 0 && (
                <thead>
                  <tr>
                    <th>№</th>
                    <th>Код</th>
                    <th>Төлөв</th>
                    {state?.loggedIn && <th>Огноо</th>}
                    <th className="text-center">Үнэ</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {data?.length > 0 ? (
                  _.map(data, (el, index) => {
                    var check = true;
                    if (!el?.paid) {
                      check = false;
                    }
                    return (
                      <tr
                        key={index}
                        className={`${check ? "bg-green-600 text-white" : ""}`}
                      >
                        <td className="text-center">{index + 1}</td>
                        <td className="text-left">{el?.track}</td>
                        <td className="text-center">{el?.status}</td>
                        {state?.loggedIn && (
                          <td className="text-center whitespace-nowrap">
                            {el?.registered}
                          </td>
                        )}
                        <td className="text-center">
                          {parseInt(el?.payment).toLocaleString()}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center font-semibold text-xl text-red-600 py-6"
                    >
                      <div className="flex items-center justify-center gap-2 flex-col">
                        <img
                          src="./item_not_found.png"
                          alt="Бараа ирээгүй байна."
                          className="object-cover h-40"
                        />
                        <div>
                          {value} дугаартай таны бараа одоогоор ирээгүй байна.
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {data?.length > 0 && (
                  <tr>
                    <td colSpan={3} className="text-right px-2 font-semibold">
                      Нийт:
                    </td>
                    <td colSpan={2} className="text-right font-semibold">
                      {totalPayment} ₮
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <div className="relative flex items-center justify-center gap-2 flex-col">
              <div className="mt-1 text-xl px-4 text-center">
                Бүртгэл хийгдэж байгаа тул ачаа тараахгүй бас админ руугаа
                залгахгүй байвал бид хурдан ялгах болно. Бүртгэл дуустал ачаа
                шалгах боломжгүй түр хүлээгээд ахин шалгана уу.
              </div>
              <img
                src="./not_working.webp"
                alt="Бараа ирээгүй байна."
                className="object-cover h-100"
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(CheckModal);
