import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "./context";
import { toast } from "react-toastify";
import { put, get, post } from "./axios";
import moment from "moment";
import _ from "lodash";
import Loader from "./components/loader";
import { ClearIcon, PasteIcon } from "./components/Icons";
import AdminHeader from "./components/adminHeader";
import { Link } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const validPrefixes = [
  "85",
  "94",
  "95",
  "99", // Мобиком ХХК
  "90",
  "91",
  "96", // Скайтел ХХК
  "80",
  "86",
  "88",
  "89", // Юнител ХХК
  "83",
  "93",
  "97",
  "98", // Жи-Мобайл ХХК
  "45",
  "46",
  "48", // Монголын Цахилгаан Холбоо ХК
  "70",
  "71", // Монголын Цахилгаан Холбоо ХК (IP)
  "26",
  "92", // ТЕГ-ын Мэдээллийн аюулгүй байдлын газар
  "75", // Мобинет ХХК
  "77", // Юнивишн ХХК
  "76", // Скаймедиа корпораци ХХК
  "78", // Жи-Мобайлнэт ХХК
  "79", // Оранжком ХХК
  "72", // Онлайм нетворк ХХК
];

const Scanner = () => {
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const [track, setTrack] = useState("");
  const { state } = useContext(Context);
  const [phone, setPhone] = useState("");
  const [payment, setPayment] = useState("");
  const [arrived, setArrived] = useState("");
  const [phones, setPhones] = useState([]);
  const [phone_items, setPhone_items] = useState([]);
  const [track_items, setTrack_items] = useState([]);
  const [active, setActive] = useState(false);
  const [pshow, setPshow] = useState(false);
  const [tshow, setTshow] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveTrack = async (track) => {
    setLoading(true);
    try {
      const res = await post("/cargo/create", {
        track: track,
        registered: moment().format("YYYY-MM-DD HH:mm"),
        status: "Эрээнд ирсэн",
      });
      if (res?.success) {
        toast.success(res?.data);
      } else {
        toast.warn(res.error);
      }
    } catch (error) {
      toast.warn(error.message);
    } finally {
      setLoading(false);
    }
  };

  const search_track = async (tr) => {
    if (tr) {
      const isSix = /^\d{6}$/.test(tr);
      if (isSix) {
        const res = await get(`/cargo/check/${tr}`);
        if (res?.success) {
          const tracks = _.map(res?.data, (a) => a.track);
          setTrack_items(tracks);
          setTshow(true);
        }
      }
    }
  };

  const check_track = async (code) => {
    if (code) {
      try {
        setLoading(true);
        const res = await get(`/cargo/check/${code?.trim()}`);
        if (res?.success) {
          if (res?.data?.length > 0) {
            if (res?.data[0]?.payment) {
              setPayment(res?.data[0]?.payment);
            }
            if (res?.data[0]?.phone) {
              setPhone(res?.data[0]?.phone);
            }
            if (res?.data[0]?.now) {
              setActive(res?.data[0]?.now === "1" ? true : false);
            }
            if (res?.data[0]?.arrived) {
              setArrived(res?.data[0]?.arrived);
            }
          } else {
            await saveTrack(code?.trim());
          }
        } else {
          toast.warn(res.error);
        }
      } catch (error) {
        toast.warn(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const sequence = Array.from({ length: 1000 }, (_, i) => 1000 + i * 500);
  const thousands = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
  ];

  const write = async (price) => {
    const res = await put("/cargo/write", {
      track: track?.toUpperCase(),
      phone: phone,
      payment: price ? price : payment,
      status: "Эрдэнэтэд ирсэн",
      arrived: arrived ? arrived : moment().format("YYYY-MM-DD HH:mm"),
      now: active ? 1 : 0,
    });
    if (res?.success) {
      toast.success("Амжилттай шинэчлэгдлээ.");
      setTrack("");
      setPayment("");
      setPhone("");
      setArrived("");
      setPhone_items([]);
      setTrack_items([]);
      inputRef1.current.focus();
    } else {
      toast.warn(res.error);
    }
  };

  const save = async () => {
    if (!track || !payment) {
      toast.warn("Тээврийн код эсвэл төлбөр оруулаагүй байна.");
      return false;
    }

    try {
      if (phone) {
        const isPhone = /^\d{8}$/.test(phone);
        if (!isPhone) {
          toast.warn("Утасны дугаар 8 оронтой байх ёстой.");
          return false;
        }

        const isValidPrefix = validPrefixes.some((validPrefix) =>
          phone.startsWith(validPrefix)
        );
        if (!isValidPrefix) {
          toast.warn("Утасны дугаарын бүтэц буруу байна.");
          return false;
        }
      }

      var price = payment;
      if (thousands?.includes(parseInt(price))) {
        price = `${payment}000`;
      }
      setPayment(parseInt(price));

      if (!sequence.includes(Number(price))) {
        confirmDialog({
          headerClassName: "p-2 text-center",
          contentClassName: "p-0",
          footerClassName: "p-2",
          header:
            "Оруулсан мөнгөн дүн буруу байх магадлалтай байна. Бүртгэхдээ итгэлтэй байна уу.",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Тийм",
          rejectLabel: "Үгүй",
          accept: async () => {
            await handleTrackCheck(price);
          },
          reject: () => {
            return;
          },
        });
      } else {
        await handleTrackCheck(price);
      }
    } catch (error) {
      toast.warn(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTrackCheck = async (price) => {
    try {
      setLoading(true);
      const res = await get(`/cargo/check/${track?.trim()}`);
      if (res?.data?.length > 0) {
        await write(price);
      } else {
        confirmDialog({
          headerClassName: "p-2 text-center",
          contentClassName: "p-0",
          footerClassName: "p-2",
          header: "Тээврийн код бүртгэлгүй байна. Шинээр бүртгэх үү?",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Тийм",
          rejectLabel: "Үгүй",
          accept: async () => {
            await check_track(track);
            await write(price);
          },
          reject: () => {
            return false;
          },
        });
      }
    } catch (error) {
      toast.warn(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    get("/cargo/ubcheck").then((res) => {
      if (res?.success) {
        if (res?.data?.length > 0) {
          var pp = [];
          _.map(res?.data, (el) => {
            if (el) {
              pp.push(el);
            }
          });
          setPhones(pp);
        }
      }
    });
  }, []);

  useEffect(() => {
    var pps = phones;
    if (phone) {
      pps = _.filter(pps, (item) => {
        return item?.endsWith(phone);
      });
      setPhone_items(pps);
    }

    if (phone.length > 2) {
      setPshow(true);
    }
    if (phone.length === 8) {
      setPshow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  window.onclick = function () {
    setTshow(false);
    setPshow(false);
  };

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  if (state?.userId !== 1)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Хандах эрхгүй.</div>
        <Link
          to={"/"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нүүр хуудасруу буцах
        </Link>
      </div>
    );

  return (
    <div>
      {loading && <Loader />}
      <AdminHeader />
      <ConfirmDialog />
      <div className="flex flex-col gap-2 mt-2 px-2 container mx-auto">
        <div className="relative w-full">
          <input
            ref={inputRef1}
            type="text"
            className="bg-white border border-white text-gray-900 rounded block p-1.5 w-full outline-none"
            value={track}
            placeholder="Тээврийн код"
            onChange={(e) => {
              setTrack(e.target.value);
            }}
            autoFocus={true}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                inputRef2.current.focus();
              } else if (e.key === "ArrowRight") {
                if (track_items?.length > 0) {
                  setTrack(track_items[0]);
                  setTshow(false);
                  inputRef2.current.focus();
                }
              } else if (e.key === "ArrowDown") {
                search_track(e.target.value);
              }
            }}
          />
          {track ? (
            <div
              className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer`}
              onClick={() => {
                setTrack("");
                setTrack_items([]);
                inputRef1.current.focus();
              }}
            >
              <ClearIcon />
            </div>
          ) : (
            <div
              className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer bg-white`}
              onClick={() => {
                navigator.clipboard
                  .readText()
                  .then((text) => {
                    setTrack(text?.trim());
                    check_track(text?.trim());
                  })
                  .catch(() => {
                    toast.info("Хуулсан текст байхгүй байна.");
                  });
              }}
            >
              <PasteIcon />
            </div>
          )}
          <div
            className={`${
              tshow ? "pshow" : "hidden"
            } absolute top-10 left-2 z-10 bg-white shadow`}
          >
            {track_items?.map((el, i) => {
              return (
                <div
                  key={i}
                  className="p-2 border-b cursor-pointer"
                  onClick={() => {
                    setTrack(el);
                    setTshow(false);
                    inputRef2.current.focus();
                  }}
                >
                  {el}
                </div>
              );
            })}
          </div>
        </div>

        <div className="relative w-full">
          <input
            ref={inputRef2}
            type="number"
            inputMode="decimal"
            pattern="[0-9]"
            className="bg-white border border-white text-gray-900 rounded block p-1.5 w-full outline-none"
            value={phone}
            placeholder="Утасны дугаар"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (phone_items.length === 1) {
                  setPhone(phone_items[0]);
                }
                setPshow(false);
                inputRef3.current.focus();
              } else if (e.key === "ArrowRight") {
                if (phone_items?.length > 0) {
                  setPhone(phone_items[0]);
                  setPshow(false);
                  inputRef3.current.focus();
                }
              }
            }}
          />
          <div
            className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer`}
            onClick={() => {
              setPhone("");
              setPhone_items([]);
              inputRef2.current.focus();
            }}
          >
            <ClearIcon />
          </div>
          <div
            className={`${
              pshow ? "pshow" : "hidden"
            } absolute top-10 left-2 z-10 bg-white shadow`}
          >
            {phone_items?.map((el, i) => {
              return (
                <div
                  key={i}
                  className="p-2 border-b cursor-pointer"
                  onClick={() => {
                    setPhone(el);
                    setPshow(false);
                    inputRef3.current.focus();
                  }}
                >
                  {el}
                </div>
              );
            })}
          </div>
        </div>
        <div className="relative w-full">
          <input
            ref={inputRef3}
            type="number"
            inputMode="decimal"
            pattern="[0-9]"
            className="bg-white border border-white text-gray-900 rounded block p-1.5 w-full outline-none"
            value={payment}
            placeholder="Үнэ"
            onChange={(e) => {
              setPayment(e.target.value);
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await save();
              }
            }}
          />

          <div
            className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer`}
            onClick={() => {
              setPayment("");
              inputRef3.current.focus();
            }}
          >
            <ClearIcon />
          </div>
        </div>
        <div className="flex items-center justify-center gap-2">
          <input
            type="checkbox"
            className="w-5 h-5"
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
          <div
            className="text-center px-2 py-1.5 rounded bg-green-600 text-white w-full"
            onClick={async () => {
              await save();
            }}
          >
            Хадгалах
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Scanner);
